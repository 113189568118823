import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../../../app.context";
import { CustomEvents, RequirementCategoryDto } from "../../../../../../../../app.types";
import { saveCategoryComment } from "./category-comments.lib";
import { updateEditCount } from "../../../response-list.lib";
import { UUID } from "gx-npm-lib";

const useReqCategoryComments = (catId: UUID) => {
  const {
    categories,
    setCategories,
    initId,
    initProductId,
    surveyId,
    setQuestionnaireEditCount,
    questionnaireEditCount,
    isPreviewMode,
  } = useContext(AppContext);
  const [commentId, setCommentId] = useState<UUID | "">("");
  const [commentDesc, setCommentDesc] = useState<string>("");

  useEffect(() => {
    const category = categories.find((req) => req.id === catId);
    if (category) {
      setCommentId(category.id);
      setCommentDesc(category.comment);
    }
  }, [catId, categories]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(CustomEvents.QUESTIONNAIRE_EDIT_COUNT, { detail: { count: questionnaireEditCount } })
    );
  }, [questionnaireEditCount]);
  const onSuccess = (newRequirements: RequirementCategoryDto[]) => {
    setCategories(newRequirements);
  };
  const handleChangeCategoryComments = async (
    config: { commentId: UUID; value: string },
    onError: (error: string | object) => void = (_error) => {
      /*do nothing*/
    }
  ) => {
    const saveCategoryCommentConfig = {
      catId,
      initId,
      initProductId,
      surveyId,
      comment: config.value,
      categories,
    };
    updateEditCount(setQuestionnaireEditCount, 1);
    if (!isPreviewMode) {
      await saveCategoryComment(saveCategoryCommentConfig, onSuccess, onError).finally(() => {
        updateEditCount(setQuestionnaireEditCount, -1);
      });
    }
  };

  return { commentId, commentDesc, handleChangeCategoryComments };
};

export default useReqCategoryComments;
