import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../../../app.context";
import {
  CustomEvents,
  ItemResponseOptionConfigType,
  RequirementCategoryDto,
  RequirementItemType,
} from "../../../../../../../../app.types";
import { saveItemResponseOption } from "./item-response-option.lib";
import { updateEditCount } from "../../../response-list.lib";
import { UUID } from "gx-npm-lib";

const useReqItemOption = (catId: UUID, itemId: UUID) => {
  const {
    categories,
    options,
    setCategories,
    initId,
    initProductId,
    surveyId,
    questionnaireEditCount,
    setQuestionnaireEditCount,
    isPreviewMode,
  } = useContext(AppContext);
  const [responseOptionIndex, setResponseOptionIndex] = useState<number>(0);

  useEffect(() => {
    const category = categories.find((req) => req.id === catId);
    if (category) {
      const item = category.items.find((req) => req.id === itemId);
      if (item) {
        const newResponseOptionIndex =
          item.responseOptionId === "" ? -1 : options.findIndex((option) => option.id === item.responseOptionId);
        setResponseOptionIndex(newResponseOptionIndex);
      }
    }
  }, [catId, itemId, categories, options]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(CustomEvents.QUESTIONNAIRE_EDIT_COUNT, { detail: { count: questionnaireEditCount } })
    );
  }, [questionnaireEditCount]);
  const onSuccess = (newCategories: RequirementCategoryDto[]) => {
    setCategories(newCategories);
  };

  const handlePreviewDataChange = (responseOptionId: UUID) => {
    const newCategories = categories.map((category) => {
      if (category.id === catId) {
        const newItems = category.items.map((item: RequirementItemType) => {
          if (item.id === itemId) {
            return { ...item, responseOptionId };
          }
          return item;
        });
        return {
          ...category,
          items: newItems,
        };
      }
      return category;
    });
    setCategories(newCategories);
  };

  const handleChangeItemResponseOption = async (
    config: ItemResponseOptionConfigType,
    onError: (error: string | object) => void = (_error) => {
      /*do nothing*/
    }
  ) => {
    const responseOptionId = options[config.responseOptionIndex]?.id;
    const data = { responseOptionId };
    const saveItemResponseOptionConfig = { catId, initId, initProductId, itemId, categories, surveyId };
    updateEditCount(setQuestionnaireEditCount, 1);
    if (isPreviewMode) {
      handlePreviewDataChange(responseOptionId);
    } else {
      await saveItemResponseOption(saveItemResponseOptionConfig, data, onSuccess, onError).finally(() => {
        updateEditCount(setQuestionnaireEditCount, -1);
      });
    }
  };
  return { responseOptionIndex, handleChangeItemResponseOption };
};

export default useReqItemOption;
