import { postAsyncRequest, isValidResponse, UUID } from "gx-npm-lib";
import { RequirementCategoryDto } from "../../../../../../../../app.types";

const saveCategoryComment = async (
  config: {
    catId: UUID;
    surveyId: UUID;
    initId: UUID;
    comment: string;
    categories: RequirementCategoryDto[];
    initProductId: UUID | "";
  },
  onSuccessResponse: (newRequirements: RequirementCategoryDto[]) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { initId, initProductId, surveyId, catId, comment, categories } = config;
  const url = `/api/v2/initiatives/${initId}/survey-responses/recipient/${surveyId}/${initProductId}/category`;
  const response = await postAsyncRequest(url, { id: catId, comment });
  if (isValidResponse(response)) {
    const newCategories = categories.map((req) => {
      if (req.id === catId) {
        if (categories) {
          return { ...req, comment };
        }
      }
      return req;
    });
    onSuccessResponse(newCategories);
  } else {
    onErrorResponse(response);
  }
};

export { saveCategoryComment };
