import React, { Fragment, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { defaultToEmptyString, getAsyncRequest } from "gx-npm-lib";
import { GartnerFooter, Loader } from "gx-npm-ui";
import styles from "./app.styles";
import { AppContext } from "./app.context";
import Responses from "./sections/responses/responses.component";
import Snackbar from "./ui/snack-bar/snack-bar.component";
import NoResponsesRecipientsView from "./sections/no-responses-recipients-view/no-responses-recipients-view.component";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { SurveyPreviewData } from "./app.types";

const useStyles = makeStyles(() => styles);
const LOCAL_STORE_PREVIEW_KEY = "survey_setup";

const AppPreview = ({ isSetupMode }: { isSetupMode: boolean }) => {
  const classes = useStyles();
  const { initiativeId: initId } = useParams();

  const {
    categories,
    isLoading,
    setCategories,
    setInitId,
    setIsLoading,
    setIsProductResponsesView,
    setIsResponseSubmitted,
    setIsSnackbarOpen,
    setOptions,
    setSurveyId,
    setIsPreviewMode,
    setSessionStorageId,
  } = useContext(AppContext);

  useEffect(() => {
    const initiativeId = defaultToEmptyString(initId);
    setIsPreviewMode(true);
    setInitId(initiativeId);
    setIsProductResponsesView(false);
    setSessionStorageId("survey-responses-expand-collapse-preview");
  }, [initId, setInitId, setIsPreviewMode, setIsProductResponsesView, setSessionStorageId]);

  useEffect(() => {
    // Preview launched from inside setup

    if (!isSetupMode) {
      return;
    }
    const updateContextWithLocalStore = (storageData: string) => {
      const parsedData: SurveyPreviewData = JSON.parse(storageData);
      setOptions(parsedData.responseOptions);
      setCategories(parsedData.selectedCategories);
    };
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === LOCAL_STORE_PREVIEW_KEY) {
        const questionnaireData = localStorage.getItem(LOCAL_STORE_PREVIEW_KEY);
        if (questionnaireData) {
          updateContextWithLocalStore(questionnaireData);
        }
      }
    };
    const initialQuestionnaireDataForResponses = localStorage.getItem(LOCAL_STORE_PREVIEW_KEY);
    if (initialQuestionnaireDataForResponses) {
      updateContextWithLocalStore(initialQuestionnaireDataForResponses);
    }

    setIsLoading(false);
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [initId, isSetupMode, setCategories, setIsLoading, setOptions]);

  useEffect(() => {
    // Preview launched from outside setup

    if (isSetupMode || !initId) {
      return;
    }
    (async () => {
      setIsLoading(true);
      const url = `api/v2/initiatives/${initId}/survey-preview/responses`;
      const response = await getAsyncRequest(url);
      if (response?.status !== 200 || !response?.data?.data || typeof response?.data?.data !== "object") {
        setIsSnackbarOpen(true);
        return;
      }
      const { categories: responseCategories, options, surveyId: sId, isSubmitted } = response.data.data;
      setCategories(responseCategories);
      setOptions(options);
      setSurveyId(sId);
      setIsResponseSubmitted(isSubmitted);
      setIsLoading(false);
    })();
  }, [
    initId,
    isSetupMode,
    setCategories,
    setIsLoading,
    setIsResponseSubmitted,
    setIsSnackbarOpen,
    setOptions,
    setSurveyId,
  ]);

  const renderComponent = () => {
    return categories.length < 1 ? <NoResponsesRecipientsView /> : <Responses />;
  };
  return (
    <Fragment>
      {isLoading && <Loader />}
      <div className={classes.responsesRoot}>
        <div className={classNames(classes.surveyResponsesView, classes.surveyResponseViewForPreview)}>
          {!isLoading && renderComponent()}
        </div>
        <GartnerFooter />
      </div>
      <Snackbar />
    </Fragment>
  );
};

export default AppPreview;
