/**
 * Initial list expand and collapse state for checklists tab.
 * @param {*} sessionId - a unique id for the group of lists
 * @param {*} lists - array of lists
 */
const persistExpandCollapse = (sessionId, lists) => {
  const setDefaults = () => {
    const phaseExpand = [];
    let firstExpand = false;
    const phaseLength = lists?.length || 0;
    for (let phaseIndex = 0; phaseIndex < phaseLength; phaseIndex++) {
      const tasks = lists?.[phaseIndex]?.tasks || [];
      if (tasks.length > 0) {
        let tasksComplete = 0;
        const taskLength = tasks?.length || 0;
        for (let taskIndex = 0; taskIndex < taskLength; taskIndex++) {
          if (tasks[taskIndex].isComplete) {
            tasksComplete = tasksComplete + 1;
          }
        }
        if (tasks.length === tasksComplete || firstExpand) {
          phaseExpand.push(false);
        } else {
          phaseExpand.push(true);
          firstExpand = true;
        }
      }
    }
    if (phaseExpand.length > 0) {
      sessionStorage.setItem(sessionId, JSON.stringify(phaseExpand));
    }
  };
  try {
    const sessionData = sessionStorage.getItem(sessionId);
    const phaseExpand = JSON.parse(sessionData);
    if (
      (!sessionData || (sessionData && !Array.isArray(phaseExpand))) &&
      lists &&
      lists.length
    ) {
      setDefaults();
    }
  } catch (_ignored) {
    setDefaults();
  }
};

const getStoredJsonItem = (storedJsonId = "") => {
  let item = null;
  if (storedJsonId) {
    try {
      const storedJson = sessionStorage.getItem(storedJsonId);
      item = JSON.parse(storedJson);
    } catch (_ignored) {
      // ignore errors
    }
  }
  return item;
};

const setStoredJsonItem = (storedJsonId = "", jsonItem = null) => {
  if (storedJsonId) {
    try {
      const jsonString = JSON.stringify(jsonItem);
      sessionStorage.setItem(storedJsonId, jsonString);
    } catch (_ignored) {
      // ignore errors
    }
  }
};

/**
 * Gets expand collapse state of list
 * @param {*} sessionId - a unique id for the group of lists
 * @param {*} index - index of an array of lists
 * @returns { boolean } boolean if list is expanded or collapsed
 */
const getExpandCollapse = (sessionId = "", index = -1) => {
  let isExpanded = true;
  const storedArray = getStoredJsonItem(sessionId);
  if (index > -1 && storedArray?.length > index) {
    if (storedArray[index] === true || storedArray[index] === false) {
      isExpanded = storedArray[index];
    }
  }
  return isExpanded;
};

/**
 * Sets expand collapse state of list
 * @param {*} sessionId - a unique id for the group of lists
 * @param {*} lists - index of an array of lists
 * @param {*} isExpanded - new boolean value for the specified list
 */
const setExpandCollapse = (sessionId = "", index = -1, isExpanded = true) => {
  if (!!sessionId && index > -1) {
    const storedArray = getStoredJsonItem(sessionId);
    const expandedArray = Array.isArray(storedArray) ? storedArray : [];
    expandedArray[index] = isExpanded;
    setStoredJsonItem(sessionId, expandedArray);
  }
};

export {
  persistExpandCollapse,
  getExpandCollapse,
  getStoredJsonItem,
  setExpandCollapse,
  setStoredJsonItem,
};
