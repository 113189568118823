import { colorPalette } from "gx-npm-common-styles";

const colorNeutralCoal = colorPalette.neutrals.coal.hex;
const colorNeutralIron = colorPalette.neutrals.iron.hex;
const colorNeutralStone = colorPalette.neutrals.stone.hex;

const noResponsesEvalViewStyles = {
  root: {
    marginTop: 70,
    marginBottom: 70,
    display: "flex",
    flexDirection: "column" as string as "column",
    alignItems: "center",
  },
  headerText: {
    color: colorNeutralIron,
    marginTop: 27,
    marginBottom: 16,
  },
  subHeaderText: {
    color: colorNeutralCoal,
    textAlign: "center" as string as "center",
  },
  navButton: {
    marginTop: 20,
  },
  infoIcon: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    marginTop: 32,
  },
  infoVector: {
    border: `1.50px ${colorNeutralStone} solid`,
    width: 20,
  },
  infoText: {
    color: colorNeutralCoal,
    marginTop: 8,
    textAlign: "center" as string as "center",
  },
};

export default noResponsesEvalViewStyles;
