import { isValidResponse, postAsyncRequest, UUID } from "gx-npm-lib";
import { ItemResponseOptionData, RequirementCategoryDto, RequirementItemType } from "../../../../../../../../app.types";

const saveItemResponseOption = async (
  config: {
    initId: UUID;
    initProductId: UUID;
    catId: UUID;
    itemId: UUID;
    categories: RequirementCategoryDto[];
    surveyId: UUID;
  },
  itemScoreData: ItemResponseOptionData,
  onSuccessResponse: (newCategories: RequirementCategoryDto[]) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { initId, initProductId, categories, catId, itemId, surveyId } = config;
  const url = `/api/v2/initiatives/${initId}/survey-responses/recipient/${surveyId}/${initProductId}/item`;
  const response = await postAsyncRequest(url, { responseOptionId: itemScoreData.responseOptionId, id: itemId });
  if (isValidResponse(response)) {
    const newCategories = categories.map((category) => {
      if (category.id === catId) {
        const newItems = category.items.map((item: RequirementItemType) => {
          if (item.id === itemId) {
            return { ...item, responseOptionId: itemScoreData.responseOptionId };
          }
          return item;
        });
        return {
          ...category,
          items: newItems,
        };
      }
      return category;
    });
    onSuccessResponse(newCategories);
  } else {
    onErrorResponse(response);
  }
};

export { saveItemResponseOption };
