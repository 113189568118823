import { colorPalette } from "gx-npm-common-styles";

const responseListHeaderStyles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  leftText: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginRight: 10,
      color: colorPalette.neutrals.iron.hex,
    },
  },
  controls: { marginTop: 0 },
};

export default responseListHeaderStyles;
