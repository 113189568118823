import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCarbon = colorPalette.neutrals.carbon.hex;
const colorIron = colorPalette.neutrals.iron.hex;
const colorSilver = colorPalette.neutrals.silver.hex;
const weightAmtRegular = weightPalette.regular.amount;
const weightVarRegular = weightPalette.regular.variation;

const listItemStyles = {
  task: {
    display: "flex",
    backgroundColor: colorPalette.basic.white.hex,
    borderRadius: 0,
    boxShadow: `inset 0px -1px 0px ${colorSilver}`,
    color: colorCarbon,
    listStyleType: "none",
    "& input": {
      boxSizing: "border-box",
      fontVariationSettings: weightVarRegular,
      fontWeight: weightAmtRegular,
      height: 48,
      paddingBottom: 13,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 14,
      "&:placeholder-shown": {
        color: colorIron,
        fontVariationSettings: weightVarRegular,
        fontWeight: weightAmtRegular,
      },
    },
    "&.complete": {
      "& input": {
        color: colorIron,
        fontVariationSettings: weightVarRegular,
        fontWeight: weightAmtRegular,
      },
    },
    "&.not-complete": {
      "& input": {
        color: colorCarbon,
        fontVariationSettings: weightPalette.regular.variation,
        fontWeight: weightPalette.medium.amount,
      },
    },
    "&:not(.no-hover)": {
      "&:hover": {
        backgroundColor: colorPalette.neutrals.quartz.hex,
        cursor: "pointer",
      },
    },
    "&.edit": {
      backgroundColor: colorPalette.interactions.lightBerry.hex,
    },
    "& fieldset": {
      border: 0,
      borderRadius: 0,
      paddingLeft: 0,
    },
  },
  classClass: { clear: "both" as string as "both" },
  draggable: {
    float: "left" as string as "left",
    height: 48,
    width: 48,
    "& svg": {
      cursor: "grab",
    },
  },
};

export { listItemStyles };
