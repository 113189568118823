import { colorPalette } from "gx-npm-common-styles";

const colorSilver = colorPalette.neutrals.silver.hex;

const listItemsContainerStyles = {
  taskList: {
    borderTop: `1px solid ${colorSilver}`,
    margin: 0,
    padding: 0,
  },
};

export { listItemsContainerStyles };
