import { Dispatch, SetStateAction } from "react";
import { UUID } from "gx-npm-lib";

export enum CustomEvents {
  QUESTIONNAIRE_EDIT_COUNT = "QUESTIONNAIRE_EDIT_COUNT",
}

export type AppContextValue = {
  isPreviewMode: boolean;
  categories: RequirementCategoryDto[];
  setCategories: Dispatch<SetStateAction<RequirementCategoryDto[]>>;
  initId: UUID;
  setInitId: Dispatch<SetStateAction<UUID>>;
  setIsPreviewMode: Dispatch<SetStateAction<boolean>>;
  initProductId: UUID;
  setInitProductId: Dispatch<SetStateAction<UUID>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isProductResponsesView: boolean;
  setIsProductResponsesView: Dispatch<SetStateAction<boolean>>;
  isResponseSubmitted: boolean;
  setIsResponseSubmitted: Dispatch<SetStateAction<boolean>>;
  isSnackbarOpen: boolean;
  setIsSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  options: ResponseOptionType[];
  setOptions: Dispatch<SetStateAction<ResponseOptionType[]>>;
  surveyId: UUID;
  setSurveyId: Dispatch<SetStateAction<UUID>>;
  sessionStorageId: string;
  setSessionStorageId: Dispatch<SetStateAction<string>>;
  hasScrolled: boolean;
  setHasScrolled: Dispatch<SetStateAction<boolean>>;
  questionnaireEditCount: number;
  setQuestionnaireEditCount: Dispatch<SetStateAction<number>>;
};

export type ResponseOptionType = {
  id: UUID;
  name: string;
};

export type RequirementCategoryDto = {
  id: UUID;
  name: string;
  comment: string;
  items: RequirementItemType[];
};

export type RequirementItemType = {
  id: UUID;
  name: string;
  comment: string;
  responseOptionId: UUID;
  description: string;
};
export type CategoryCommentUpdateDto = {
  commentId: UUID;
  comment: string;
};
export type ItemResponseOptionConfigType = {
  responseOptionIndex: number;
};
export type ItemResponseOptionData = {
  responseOptionId: UUID;
};

export type SurveyPreviewItem = {
  id: UUID;
  index: number;
  isDeleted?: boolean;
  name: string;
};

export type SurveyPreviewData = {
  companyName: string;
  instructions: string;
  requestedDocuments: SurveyPreviewItem[];
  responseOptions: SurveyPreviewItem[];
  selectedCategories: RequirementCategoryDto[];
  isValid: boolean;
  requirementsCount: number;
  initiativeId: string;
};
