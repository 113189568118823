import { colorPalette } from "gx-npm-common-styles";

const surveyWorkspaceHeaderMaxHeight = 212;
const evalWorkspaceHeaderMaxHeight = 354;
const surveyPreviewAdjustment = 48;
const footerHeight = 128;

const surveyResponsesAppStyles = {
  responsesRoot: {
    display: "flex",
    flexDirection: "column" as const,
    color: colorPalette.neutrals.coal.hex,
  },
  surveyResponsesView: {
    minHeight: `calc(100vh - ${footerHeight + surveyWorkspaceHeaderMaxHeight}px)`,
  },
  surveyResponseViewForPreview: {
    minHeight: `calc(100vh - ${footerHeight + surveyWorkspaceHeaderMaxHeight + surveyPreviewAdjustment}px)`,
  },
  evalResponsesView: {
    minHeight: `calc(100vh - ${footerHeight + evalWorkspaceHeaderMaxHeight}px)`,
  },
};

export default surveyResponsesAppStyles;
