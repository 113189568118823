import { colorPalette } from "gx-npm-common-styles";

const colorCarbon = colorPalette.neutrals.carbon.hex;
const colorStone = colorPalette.neutrals.stone.hex;

const responseListHeaderStyles = {
  headerRoot: {
    position: "relative" as string as "relative",
    width: "100%",
    "& h5": {
      maxWidth: "50%",
      "@media (max-width:1024px)": {
        flexBasis: "88%",
        maxWidth: "100%",
      },
    },
    "& .gx-survey-responses-expand-button": {
      marginRight: 8,
    },
    "& $buttonOptOut": {
      marginLeft: "auto",
      marginRight: "10px",
      marginTop: "8px",
      "@media (max-width:1150px)": {
        marginLeft: "46px",
      },
    },
  },
  carbon: {
    color: colorCarbon,
  },
  namePercentSplitter: {
    borderLeft: `1px solid ${colorStone}`,
    height: "22px",
    margin: "0px 16px",
  },
  top: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row" as const,
  },
  headerWrap: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap" as const,
    alignItems: "center",
  },
  right: {
    alignItems: "center",
    display: "flex",
    order: 2,
    "& .tool-tip": {
      display: "flex",
      width: "unset",
    },
  },
  bottom: {
    borderTop: `1px solid ${colorStone}`,
    marginTop: 20,
    paddingTop: 37,
    "& .gx-survey-responses-list-header-comments": {
      marginLeft: 12,
      "& textarea": {
        height: 164,
        maxWidth: 600,
        width: "calc(100% - 12px)",
      },
    },
  },
  bottomShowAllComments: {
    paddingTop: 18,
  },
  buttonOptOut: {},
  commentsTitle: { marginBottom: 12, color: colorPalette.neutrals.iron.hex },
  commentsWrapper: { width: "60%" },
};

export default responseListHeaderStyles;
