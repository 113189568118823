import { isValidResponse, postAsyncRequest, UUID } from "gx-npm-lib";
import { RequirementCategoryDto, CategoryCommentUpdateDto } from "../../../../../../../../app.types";

const saveItemComment = async (
  config: {
    initId: UUID;
    initProductId: UUID;
    surveyId: UUID;
    itemId: UUID;
    catId: UUID;
    categories: RequirementCategoryDto[];
    value: string;
    commentId: UUID;
  },
  itemCommentData: CategoryCommentUpdateDto,
  onSuccessResponse: (newRequirements: RequirementCategoryDto[]) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { value, commentId, initId, initProductId, categories, catId, itemId, surveyId } = config;
  const url = `/api/v2/initiatives/${initId}/survey-responses/recipient/${surveyId}/${initProductId}/item`;
  const response = await postAsyncRequest(url, { id: itemId, comment: value });
  if (isValidResponse(response)) {
    const newCategories = categories.map((category) => {
      if (category.id !== catId) {
        return category;
      }

      const newItems = category.items.map((item) => {
        if (item.id !== itemId) {
          return item;
        }
        if (commentId) {
          return { ...item, id: commentId, comment: value };
        } else {
          return { ...item, id: response.data.data.id, comment: value };
        }
      });
      return { ...category, items: newItems };
    });
    onSuccessResponse(newCategories);
  } else {
    onErrorResponse(response);
  }
};

export { saveItemComment };
