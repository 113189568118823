import { useContext, useEffect, useState } from "react";
import { saveItemComment } from "./item-comments.lib";
import { AppContext } from "../../../../../../../../app.context";
import { CustomEvents, RequirementCategoryDto } from "../../../../../../../../app.types";
import { updateEditCount } from "../../../response-list.lib";
import { UUID } from "gx-npm-lib";

const useReqItemComments = (catId: UUID, itemId: UUID) => {
  const {
    initId,
    initProductId,
    categories,
    setCategories,
    surveyId,
    questionnaireEditCount,
    setQuestionnaireEditCount,
    isPreviewMode,
  } = useContext(AppContext);
  const [commentId, setCommentId] = useState<UUID | "">("");
  const [commentDesc, setCommentDesc] = useState<string>("");

  useEffect(() => {
    const category = categories.find((cat) => cat.id === catId);
    if (category) {
      const item = category.items.find((req) => req.id === itemId);
      if (item) {
        setCommentId(item.id);
        setCommentDesc(item.comment);
      }
    }
  }, [catId, itemId, categories]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(CustomEvents.QUESTIONNAIRE_EDIT_COUNT, { detail: { count: questionnaireEditCount } })
    );
  }, [questionnaireEditCount]);

  const onSuccess = (newCategories: RequirementCategoryDto[]) => {
    setCategories(newCategories);
  };
  const handleChangeItemComments = async (
    config: { commentId: UUID; value: string },
    onError: (error: string | object) => void = (_error) => {
      /*do nothing*/
    }
  ) => {
    const { commentId: itemCommentId, value } = config;
    const saveItemCommentConfig = {
      ...config,
      catId,
      itemId,
      surveyId,
      initId,
      initProductId,
      categories,
    };
    const data = { comment: value, commentId: itemCommentId };
    updateEditCount(setQuestionnaireEditCount, 1);
    if (!isPreviewMode) {
      await saveItemComment(saveItemCommentConfig, data, onSuccess, onError).finally(() => {
        updateEditCount(setQuestionnaireEditCount, -1);
      });
    }
  };

  return { commentId, commentDesc, handleChangeItemComments };
};

export default useReqItemComments;
