import { colorPalette } from "gx-npm-common-styles";
import { headerHeights } from "../../app.constants";

const { navigation, vendorInfo } = headerHeights;
const topDefaultScrolled = navigation + vendorInfo.mediaScrolled;

const responsesStyles = {
  responses: {
    color: colorPalette.neutrals.coal.hex,
    backgroundColor: colorPalette.neutrals.quartz.hex,
    width: "100%",
    marginTop: 0,
    "&.scrolled": {
      "& .gx-survey-responses-list-header, .gx-sidebar-container": {
        top: topDefaultScrolled,
      },
    },
  },
  previewScrolled: {
    "& .gx-survey-responses-list-header, .gx-sidebar-container": {
      top: topDefaultScrolled + 48,
    },
  },
  form: {
    maxWidth: "1440px",
    minWidth: "1024px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "32px 56px",
  },
  card: {
    borderRadius: 8,
    marginBottom: 24,
  },
};

export default responsesStyles;
