import React, { Fragment, useState } from "react";
import useReqItemComments from "./context/item-comments/use-req-item-comments.hook";
import useReqItemOption from "./context/item-response-option/use-req-item-response-option.hook";
import { UUID } from "gx-npm-lib";
import { SnackbarBanner } from "gx-npm-ui";
import { reqComm } from "../../../../../../app.constants";
import ResponseOptionAndCommentRow from "./response-option-and-comment-row/response-option-and-comment-row.component";

type ScoreListBodyProps = {
  catId: UUID;
  description: string;
  name: string;
  reqId: UUID;
};

const ResponseListBodyItem: React.FC<ScoreListBodyProps> = ({ catId, description, name, reqId }) => {
  const { commentDesc, commentId, handleChangeItemComments } = useReqItemComments(catId, reqId);
  const { responseOptionIndex, handleChangeItemResponseOption } = useReqItemOption(catId, reqId);
  const [isSaveError, setIsSaveError] = useState(false);

  const onError = () => {
    setIsSaveError(true);
    setTimeout(() => setIsSaveError(false), 3000);
  };
  const handleSave = (key: string, value: string | number) => {
    if (key === reqComm) {
      handleChangeItemComments({ commentId, value: value as string }, onError);
    } else {
      handleChangeItemResponseOption({ responseOptionIndex: value as number }, onError);
    }
  };

  return (
    <Fragment>
      <ResponseOptionAndCommentRow
        comments={commentDesc}
        description={description}
        name={name}
        onSave={handleSave}
        responseOptionIndex={responseOptionIndex}
      />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={isSaveError} />
    </Fragment>
  );
};

export default ResponseListBodyItem;
