export const reqComm = "comments";
export const respOption = "responseOption";
export const headerHeights = {
  navigation: 72,
  preview: 72,
  vendorInfo: {
    mediaSmall: 180,
    mediaStandard: 96,
    mediaScrolled: 47,
  },
};
