import { createContext, ReactNode, useState } from "react";
import { AppContextValue, RequirementCategoryDto, ResponseOptionType } from "./app.types";
import { UUID } from "gx-npm-lib";

const AppContext = createContext<AppContextValue>({} as AppContextValue);

const AppContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [options, setOptions] = useState<ResponseOptionType[]>([]);
  const [categories, setCategories] = useState<RequirementCategoryDto[]>([]);
  const [initProductId, setInitProductId] = useState<UUID>("");
  const [initId, setInitId] = useState<UUID>("");
  const [surveyId, setSurveyId] = useState<UUID>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProductResponsesView, setIsProductResponsesView] = useState<boolean>(false);
  const [isResponseSubmitted, setIsResponseSubmitted] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [sessionStorageId, setSessionStorageId] = useState("");
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [questionnaireEditCount, setQuestionnaireEditCount] = useState<number>(0);
  const [isPreviewMode, setIsPreviewMode] = useState(false);

  const contextValue = {
    isViewOnly,
    options,
    categories,
    initProductId,
    initId,
    surveyId,
    isLoading,
    isProductResponsesView,
    isResponseSubmitted,
    isSnackbarOpen,
    sessionStorageId,
    hasScrolled,
    questionnaireEditCount,
    isPreviewMode,
    setIsViewOnly,
    setOptions,
    setCategories,
    setInitProductId,
    setInitId,
    setSurveyId,
    setIsLoading,
    setIsProductResponsesView,
    setIsResponseSubmitted,
    setIsSnackbarOpen,
    setSessionStorageId,
    setHasScrolled,
    setQuestionnaireEditCount,
    setIsPreviewMode,
  };

  return <AppContext.Provider value={contextValue}>{props.children}</AppContext.Provider>;
};

export { AppContext, AppContextProvider };
