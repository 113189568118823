import React, { useContext, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import responseListControlsStyles from "./response-list-controls.styles";
import { useTranslation } from "react-i18next";
import { colorPalette } from "gx-npm-common-styles";
import { MiniButton, TooltipV2, IconButton } from "gx-npm-ui";
import { EyeIcon, EyeSlashIcon, GearIcon } from "gx-npm-icons";
import { ResponseFormContext } from "../../response-form/response-form.context";

const useStyles = makeStyles(() => responseListControlsStyles);
const ResponseListControls = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [slideOut, setSlideOut] = useState(true);
  const { showAllComments, setShowAllComments, setShowAllDescriptions, showAllDescriptions } =
    useContext(ResponseFormContext);
  const mildBerrySixtyOpacityEquivalent = "#CEDDF6";
  const handleIconClick = () => {
    setSlideOut(!slideOut);
  };
  const handleClickCommentsButton = () => {
    setShowAllComments(!showAllComments);
  };
  const handleClickDescriptionButton = () => {
    setShowAllDescriptions(!showAllDescriptions);
  };

  return (
    <div className={classNames(classes.responseContentControls)}>
      <div className={classes.buttonsWrapper}>
        <div
          className={classNames(
            classes.hideShowButtons,
            "gx-survey-responses-hide-show-buttons-container",
            slideOut && "slide-out"
          )}
        >
          {showAllDescriptions ? (
            <MiniButton
              onClick={handleClickDescriptionButton}
              rootClassName={"gx-survey-responses-hide-all-description-button"}
            >
              <EyeSlashIcon fillPath={colorPalette.interactions.blueBerry.hex} />
              {t("Hide all descriptions")}
            </MiniButton>
          ) : (
            <MiniButton
              onClick={handleClickDescriptionButton}
              rootClassName={"gx-survey-responses-show-all-description-button"}
            >
              <EyeIcon fillPath={colorPalette.interactions.blueBerry.hex} />
              {t("Show all descriptions")}
            </MiniButton>
          )}
          <div className={classNames(classes.verticalLine)} />
          {showAllComments ? (
            <MiniButton onClick={handleClickCommentsButton} rootClassName={"gx-survey-responses-show-comments-button"}>
              <EyeSlashIcon fillPath={colorPalette.interactions.blueBerry.hex} />
              {t("Hide all comments")}
            </MiniButton>
          ) : (
            <MiniButton onClick={handleClickCommentsButton} rootClassName={"gx-survey-responses-show-comments-button"}>
              <EyeIcon fillPath={colorPalette.interactions.blueBerry.hex} />
              {t("Show all comments")}
            </MiniButton>
          )}
        </div>
        <TooltipV2
          title={t("View controls for showing and hiding requirement descriptions and comments.")}
          rootClassName={"gx-survey-responses-form-controls-icon-tool-tip"}
          placement="top"
          PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
          enterDelay={400}
          enterNextDelay={400}
        >
          <div>
            <IconButton
              onClick={handleIconClick}
              className={classNames(
                "gx-survey-responses-form-controls-icon-button",
                slideOut ? "not-selected" : "selected"
              )}
              borderRadius={"8px"}
              hoverBackgroundColor={mildBerrySixtyOpacityEquivalent}
            >
              <GearIcon />
            </IconButton>
          </div>
        </TooltipV2>
      </div>
    </div>
  );
};

export default ResponseListControls;
