const formListStyles = {
  content: {
    "& .gx-survey-responses-list-item": {
      display: "block",
      paddingTop: 20,
      paddingRight: 20,
      paddingBottom: 20,
      paddingLeft: 32,
      "&:last-of-type": {
        borderRadius: "0px 0px 8px 8px",
        boxShadow: "none",
      },
    },
  },
};

export default formListStyles;
