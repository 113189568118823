import { colorPalette } from "gx-npm-common-styles";

const messageIconStyles = {
  messageIconWrapper: {
    "&:hover": {
      "& svg path": {
        fill: colorPalette.neutrals.coal.hex,
      },
    },
  },
  hiddenIcon: { visibility: "hidden" as const },
};

export { messageIconStyles };
