const getListHeaderStyles = (sticky = 48, index = -1) => {
  const stickyStyles = {
    position: "sticky" as string as "sticky",
    top: sticky,
    "@media (max-width:1239px)": {
      top: sticky + 32,
    },
  };

  return {
    listHeader: {
      display: "flex",
      backgroundColor: "var(--color-white)",
      borderRadius: "8px 8px 0px 0px",
      padding: 20,
      zIndex: 1024 - index,
      ...(sticky >= 0 ? stickyStyles : {}),
      "&:hover": {
        zIndex: 1025 - index,
      },
      "&.collapse": {
        borderRadius: "8px 8px 8px 8px",
        boxShadow: "none",
        "-webkit-transition": "border-radius",
        "-webkit-transition-duration": "0.25s",
        "-webkit-transition-delay": "0.25s",
        "-webkit-transition-timing-function": "linear",
      },
      "& fieldset": {
        border: 0,
        borderRadius: 0,
      },
    },
  };
};

export default getListHeaderStyles;
