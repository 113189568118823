import { colorPalette, weightPalette } from "gx-npm-common-styles";

const noResponsesRecipientsViewStyles = {
  noResponsesRoot: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column" as const,
    paddingTop: "90px",
    width: "100%",
  },
  noResponsesHeaderText: {
    color: colorPalette.neutrals.iron.hex,
    fontSize: "28px",
    lineHeight: "130%",
    letterSpacing: "0.15px",
    paddingTop: "36px",
    textAlign: "center" as const,
  },
  noResponsesSubHeaderText: {
    color: colorPalette.neutrals.coal.hex,
    fontSize: "14px",
    fontVariationSettings: weightPalette.regular.variation,
    fontWeight: weightPalette.regular.amount,
    lineHeight: "150%",
    letterSpacing: "0.25px",
    paddingTop: "15px",
    textAlign: "center" as const,
  },
  headerRegular: {
    fontVariationSettings: weightPalette.regular.variation,
    fontWeight: weightPalette.regular.amount,
  },
};

export default noResponsesRecipientsViewStyles;
