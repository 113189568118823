// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LnrNt85eWuNOLSBvhewA .gx-dialog-body{margin-left:-36px;width:600px}.Qld16nYpxC6rQzTCxN6x{padding:24px 36px 56px 36px}.Ee2znwEOn47emAmfEfc3{background:var(--color-interactions-light-berry);padding:56px 0 74px 36px}.Ee2znwEOn47emAmfEfc3 .DJZlQJUxyIK1fWS46972{display:block}.Ee2znwEOn47emAmfEfc3 .b7Jl3GcLAo58Nc_j1sqV{position:relative}.Ee2znwEOn47emAmfEfc3 .b7Jl3GcLAo58Nc_j1sqV .SIHjbz17ChguXMQo4v7g{bottom:-74px;position:absolute;right:0}.F8SnBgAbs5KiksaqC6Hm{align-items:center;display:flex;justify-content:flex-end}.F8SnBgAbs5KiksaqC6Hm .nLzd6QmcYKWNsJvT4_8y{margin-right:24px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-init-create-modal/free-trial-init-create-modal.styles.module.scss"],"names":[],"mappings":"AACE,sCACE,iBAAA,CACA,WAAA,CAIJ,sBACE,2BAAA,CAGF,sBACE,gDAAA,CACA,wBAAA,CAEA,4CACE,aAAA,CAEF,4CACE,iBAAA,CAEA,kEACE,YAAA,CACA,iBAAA,CACA,OAAA,CAKN,sBACE,kBAAA,CACA,YAAA,CACA,wBAAA,CAEA,4CACE,iBAAA","sourcesContent":[".dialogRoot {\n  & :global(.gx-dialog-body) {\n    margin-left: -36px;\n    width: 600px;\n  }\n}\n\n.subtitleParagraph {\n  padding: 24px 36px 56px 36px\n}\n\n.remainingInitsHeading {\n  background: var(--color-interactions-light-berry);\n  padding: 56px 0 74px 36px;\n\n  & .headingSecondLine {\n    display: block;\n  }\n  & .cornerImgContainer {\n    position: relative;\n\n    & .cornerImg {\n      bottom: -74px;\n      position: absolute;\n      right: 0;\n    }\n  }\n}\n\n.footerButtonContainer {\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n\n  & .cancelButton {\n    margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogRoot": `LnrNt85eWuNOLSBvhewA`,
	"subtitleParagraph": `Qld16nYpxC6rQzTCxN6x`,
	"remainingInitsHeading": `Ee2znwEOn47emAmfEfc3`,
	"headingSecondLine": `DJZlQJUxyIK1fWS46972`,
	"cornerImgContainer": `b7Jl3GcLAo58Nc_j1sqV`,
	"cornerImg": `SIHjbz17ChguXMQo4v7g`,
	"footerButtonContainer": `F8SnBgAbs5KiksaqC6Hm`,
	"cancelButton": `nLzd6QmcYKWNsJvT4_8y`
};
export default ___CSS_LOADER_EXPORT___;
