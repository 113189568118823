import NoResponsesSvg from "../../assets/images/no-responses.svg";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./no-responses-recipients-view.styles";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";
import classNames from "classnames";

const useStyles = makeStyles(() => styles);

const NoResponsesRecipientsView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.noResponsesRoot}>
      <img src={NoResponsesSvg} alt="no-responses" />
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h3"} boldness={"medium"} rootClassName={classes.noResponsesHeaderText}>
            {t("You have no survey questions to respond to.")}
          </TypographyComponent>
          <TypographyComponent styling={"p3"} rootClassName={classes.noResponsesSubHeaderText}>
            {t("Survey response for this vendor will")}
            <br />
            {t("appear when they share responses to an active survey.")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className={classNames(classes.noResponsesHeaderText, classes.headerRegular)}>
            {t("You have no survey questions to respond to.")}
          </p>
          <p className={classes.noResponsesSubHeaderText}>
            {t("Survey response for this vendor will")}
            <br />
            {t("appear when they share responses to an active survey.")}
          </p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </div>
  );
};

export default NoResponsesRecipientsView;
