const listHeaderExpandButtonStyles = {
  iconExpand: {
    float: "left" as string as "left",
    "&:not(.gx-is-expanded) svg": {
      transform: "rotate(180deg)",
    },
    "& button": {
      bottom: 1,
    },
  },
};

export default listHeaderExpandButtonStyles;
