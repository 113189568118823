import React, { Fragment, useState } from "react";
import CategoryHeader from "./category-header/category-header.component";
import useReqCategoryComments from "./context/category-comments/use-req-category-comments.hook";
import { UUID } from "gx-npm-lib";
import { SnackbarBanner } from "gx-npm-ui";

// NOTE - expand and setExpand are injected into header through clone process

type CategoryListHeaderProps = {
  catId: UUID;
  expand?: boolean;
  name: string;
  setExpand?: (expand: boolean) => void;
};

const CategoryListHeader: React.FC<CategoryListHeaderProps> = ({ catId, name, expand, setExpand }) => {
  const { commentDesc, commentId, handleChangeCategoryComments } = useReqCategoryComments(catId);
  const [isSaveError, setIsSaveError] = useState(false);

  const handleSaveComment = (value: string) => {
    const onError = () => {
      setIsSaveError(true);
      setTimeout(() => setIsSaveError(false), 3000);
    };

    handleChangeCategoryComments({ commentId, value }, onError);
  };
  return (
    <Fragment>
      <CategoryHeader
        comment={commentDesc}
        headerExpand={expand}
        name={name}
        onSaveComment={handleSaveComment}
        headerSetExpand={setExpand}
      />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={isSaveError} />
    </Fragment>
  );
};

export default CategoryListHeader;
