import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { getStoredJsonItem, setStoredJsonItem, UUID } from "gx-npm-lib";
import { NotifyWhenStickyDetachV2Component } from "gx-npm-ui";
import { useCallback, useEffect, useRef, useState } from "react";
import { RequirementItemType } from "../../../../app.types";
import ListItemsContainer from "../../../../ui/list/body/list-items-container/list-items-container.component";
import ListItemComponent from "../../../../ui/list/body/list-item/list-item.component";
import List from "../../../../ui/list/header/list/list.component";
import CategoryListHeader from "./response-list/category-list-header/category-list-header.component";
import ResponseListBodyItem from "./response-list/response-list-body-item/response-list-body-item.component";
import styles from "./response-form-list.styles";

const PAGE_HEADER_HEIGHT_IN_PX = 128;
type ResponseFormListProps = {
  catId: UUID;
  catName: string;
  index: number;
  requirementsList: RequirementItemType[];
  sessionStorageId: string;
  totalCategories: number;
};
const useStyles = makeStyles(() => styles);
const ResponseFormList = ({
  catId,
  catName,
  index = -1,
  requirementsList,
  sessionStorageId,
}: ResponseFormListProps) => {
  const classes = useStyles();
  const contentRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoadSessionData, setIsLoadSessionData] = useState(false);

  const handleExpandState = useCallback(
    (val) => {
      const data = getStoredJsonItem(sessionStorageId);
      setIsExpanded(val);
      if (data && requirementsList.length > 100) {
        for (const id of Object.keys(data)) {
          data[id] = id === catId ? val : false;
        }
      }
      setStoredJsonItem(sessionStorageId, { ...data, [catId]: val });
    },
    [catId, sessionStorageId, requirementsList.length]
  );

  useEffect(() => {
    if (!sessionStorageId || !catId) {
      return;
    }
    const sessionData = getStoredJsonItem(sessionStorageId);
    const expandState = sessionData?.[catId];
    setIsExpanded(expandState);
    setIsLoadSessionData(true);
    if (index === 0 && !sessionData) {
      handleExpandState(true);
    }
  }, [catId, handleExpandState, index, sessionStorageId]);

  if (!isLoadSessionData) {
    return null;
  }

  return (
    <NotifyWhenStickyDetachV2Component
      dependencyData={[isExpanded]}
      reference={contentRef}
      topOffsetInPx={PAGE_HEADER_HEIGHT_IN_PX}
    >
      <div className={classNames(classes.content)} ref={contentRef}>
        <List defaultExpand={isExpanded || false} handleExpand={handleExpandState}>
          <CategoryListHeader catId={catId} name={catName} />
          <ListItemsContainer>
            {isExpanded &&
              requirementsList?.map((item) => {
                const { description, id, name } = item;
                return (
                  <ListItemComponent key={id} rootClassName="gx-survey-responses-list-item">
                    <ResponseListBodyItem catId={catId} description={description} name={name} reqId={id} />
                  </ListItemComponent>
                );
              })}
          </ListItemsContainer>
        </List>
      </div>
    </NotifyWhenStickyDetachV2Component>
  );
};

export default ResponseFormList;
