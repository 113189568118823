import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./responses.styles";
import ResponsesListHeader from "./response-list-header/response-list-header.component";
import ResponseForm from "./response-form/response-form.component";
import classNames from "classnames";
import { AppContext } from "../../app.context";
import { ResponseFormContextProvider } from "./response-form/response-form.context";

const useStyles = makeStyles(() => styles);

const Responses = () => {
  const classes = useStyles();
  const { hasScrolled, isPreviewMode, setHasScrolled } = useContext(AppContext);

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setHasScrolled]);

  return (
    <div
      className={classNames(hasScrolled && (isPreviewMode ? classes.previewScrolled : "scrolled"), classes.responses)}
    >
      <div className={classes.form}>
        <ResponseFormContextProvider>
          <ResponsesListHeader />
          <div className={classes.card}>{<ResponseForm />}</div>
        </ResponseFormContextProvider>
      </div>
    </div>
  );
};

export default Responses;
