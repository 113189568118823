import { makeStyles } from "@material-ui/core";
import { STICKY_HEADER_IGNORE_CLASS, STICKY_HEADER_WRAPPER_CLASS } from "gx-npm-ui";
import classNames from "classnames";
import { ReactElement, useState } from "react";
import getListHeaderStyles from "./list-header.styles";

type ListHeaderProps = {
  expand?: boolean;
  index?: number;
  rootClassName?: string;
  setExpand?: (expand: boolean) => void;
  sticky: number;
  renderItem: (expand: boolean | undefined, setExpand: ((expand: boolean) => void) | undefined) => ReactElement;
};
const ListHeader = ({ expand, index = 0, rootClassName = "", setExpand, sticky, renderItem }: ListHeaderProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const listHeaderStyles = getListHeaderStyles(sticky, index);
  const useStyles = makeStyles(() => listHeaderStyles);
  const classes = useStyles();

  const handleMouseEvent = (type: string) => {
    setIsHovering("enter" === type || "move" === type);
  };

  return (
    <section
      className={classNames(
        "gx-survey-responses-list-header",
        "list-header",
        STICKY_HEADER_WRAPPER_CLASS,
        rootClassName,
        classes.listHeader,
        expand ? "expand" : "collapse",
        !expand && STICKY_HEADER_IGNORE_CLASS,
        isHovering && "hover"
      )}
      onBlur={() => handleMouseEvent("blur")}
      onMouseEnter={() => handleMouseEvent("enter")}
      onMouseMove={() => handleMouseEvent("move")}
      onMouseOut={() => handleMouseEvent("out")}
    >
      {renderItem(expand, setExpand)}
    </section>
  );
};

export default ListHeader;
