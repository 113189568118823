import React, { createContext, useState } from "react";

export type ResponseFormContextValue = {
  showAllComments: boolean;
  setShowAllComments: React.Dispatch<React.SetStateAction<boolean>>;
  showAllDescriptions: boolean;
  setShowAllDescriptions: React.Dispatch<React.SetStateAction<boolean>>;
};

const ResponseFormContext = createContext<ResponseFormContextValue>({} as ResponseFormContextValue);

const ResponseFormContextProvider = (props: { children: React.ReactNode | Array<React.ReactNode> }) => {
  const [showAllComments, setShowAllComments] = useState(false);
  const [showAllDescriptions, setShowAllDescriptions] = useState(true);

  const contextValue = {
    showAllComments,
    setShowAllComments,
    showAllDescriptions,
    setShowAllDescriptions,
  };

  return <ResponseFormContext.Provider value={contextValue}>{props.children}</ResponseFormContext.Provider>;
};

export { ResponseFormContext, ResponseFormContextProvider };
