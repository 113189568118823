import React, { Fragment, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { defaultToEmptyString, getAsyncRequest, isValidResponse } from "gx-npm-lib";
import { GartnerFooter, Loader } from "gx-npm-ui";
import styles from "./app.styles";
import { AppContext } from "./app.context";
import Responses from "./sections/responses/responses.component";
import Snackbar from "./ui/snack-bar/snack-bar.component";
import NoResponsesEvalView from "./sections/no-responses-eval-view/no-responses-eval-view.component";
import NoResponsesRecipientsView from "./sections/no-responses-recipients-view/no-responses-recipients-view.component";
import { useParams } from "react-router-dom";
import classNames from "classnames";

const useStyles = makeStyles(() => styles);

const App = () => {
  const classes = useStyles();
  const { initiativeId: initId, initProductId } = useParams();

  const {
    categories,
    isLoading,
    isProductResponsesView,
    isResponseSubmitted,
    surveyId,
    setCategories,
    setInitId,
    setInitProductId,
    setIsLoading,
    setIsProductResponsesView,
    setIsResponseSubmitted,
    setIsSnackbarOpen,
    setOptions,
    setSurveyId,
    setSessionStorageId,
  } = useContext(AppContext);

  useEffect(() => {
    const pathName = window.location.pathname;
    const initiativeId = defaultToEmptyString(initId);
    const initProdId = defaultToEmptyString(initProductId);
    const isProductResponsesPath = !pathName.includes("questionnaire");
    setInitId(initiativeId);
    setInitProductId(initProdId);
    if (isProductResponsesPath) {
      setIsProductResponsesView(true);
    }
    const id = `survey-responses-expand-collapse-${initProdId}`;
    setSessionStorageId(id);

    (async () => {
      setIsLoading(true);
      try {
        const userType = isProductResponsesPath ? "evaluator" : "recipient";
        const url = `api/v2/initiatives/${initiativeId}/survey-responses/${userType}/${initProdId}/responses`;
        const response = await getAsyncRequest(url);
        if (!isValidResponse(response)) {
          throw new Error();
        }
        const { categories: responseCategories, options, surveyId: sId, isSubmitted } = response.data.data;
        setCategories(responseCategories);
        setOptions(options);
        setSurveyId(sId);
        setIsResponseSubmitted(isSubmitted);
        setIsLoading(false);
      } catch (err) {
        setIsSnackbarOpen(true);
      }
    })();
  }, [
    setCategories,
    setInitId,
    setInitProductId,
    setIsLoading,
    setIsSnackbarOpen,
    setOptions,
    setSurveyId,
    setSessionStorageId,
    setIsResponseSubmitted,
    setIsProductResponsesView,
    initId,
    initProductId,
  ]);

  const renderComponent = () => {
    if (isProductResponsesView && (surveyId === "" || !isResponseSubmitted)) {
      return <NoResponsesEvalView />;
    } else if (!isProductResponsesView && categories.length < 1) {
      return <NoResponsesRecipientsView />;
    } else {
      return <Responses />;
    }
  };
  return (
    <Fragment>
      {isLoading && <Loader />}
      <div className={classes.responsesRoot}>
        <div className={classNames(isProductResponsesView ? classes.evalResponsesView : classes.surveyResponsesView)}>
          {!isLoading && renderComponent()}
        </div>
        <GartnerFooter />
      </div>
      <Snackbar />
    </Fragment>
  );
};

export default App;
