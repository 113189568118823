import React, { useContext } from "react";
import { AppContext } from "../../../app.context";
import ResponseFormList from "./response-form-list/response-form-list.component";
const ResponseForm = () => {
  const { categories, sessionStorageId } = useContext(AppContext);

  return (
    <div>
      {categories.map((data, idx) => {
        const { id, items, name } = data;
        return (
          <ResponseFormList
            catId={id}
            catName={name}
            index={idx}
            key={id || idx}
            requirementsList={items}
            sessionStorageId={sessionStorageId}
            totalCategories={categories.length}
          />
        );
      })}
    </div>
  );
};

export default ResponseForm;
